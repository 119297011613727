// Generated by Framer (4175fb7)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["O54PBVnnW"];

const serializationHash = "framer-Vlhe7"

const variantClassNames = {O54PBVnnW: "framer-v-xgmvhy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "O54PBVnnW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xgmvhy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"O54PBVnnW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0N1dG1hcmsgUmVndWxhcg==", "--framer-font-family": "\"Cutmark Regular\", \"Cutmark Regular Placeholder\", sans-serif", "--framer-font-size": "48px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Somos a Scoder uma Software house</motion.p></React.Fragment>} className={"framer-kat96y"} fonts={["CUSTOM;Cutmark Regular"]} layoutDependency={layoutDependency} layoutId={"D306F05qg"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Vlhe7.framer-10evobo, .framer-Vlhe7 .framer-10evobo { display: block; }", ".framer-Vlhe7.framer-xgmvhy { height: 115px; overflow: hidden; position: relative; width: 411px; }", ".framer-Vlhe7 .framer-kat96y { flex: none; height: auto; left: 0px; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115
 * @framerIntrinsicWidth 411
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbQEkXsO5l: React.ComponentType<Props> = withCSS(Component, css, "framer-Vlhe7") as typeof Component;
export default FramerbQEkXsO5l;

FramerbQEkXsO5l.displayName = "Text hero section";

FramerbQEkXsO5l.defaultProps = {height: 115, width: 411};

addFonts(FramerbQEkXsO5l, [{explicitInter: true, fonts: [{family: "Cutmark Regular", source: "custom", url: "https://framerusercontent.com/assets/GKORvopOTXiqQZojroBPss0Es0.woff2"}]}], {supportsExplicitInterCodegen: true})